import React from 'react';
import './app.css';
import Footer from './components/Footer';
import Hero from './components/Hero';
import Navbar from './components/Navbar';
import Recommend from './components/Recommend';
import ScrollToTop from './components/ScrollToTop';
import Services from './components/Services';
import Testimonials from './components/Testimonials';

function App() {
  return (
    <>
        <Navbar/>
    <ScrollToTop/>

    <Hero/>
    <Services/>
    <Recommend/>
    <Testimonials/>
   <Footer/>

    </>
  
    
  );
}

export default App;
